






import { Vue, Component, State } from 'nuxt-property-decorator'

@Component({
  components: {
    desktop: () => import('~/components/layouts/default/desktop' /* webpackChunkName: 'layouts-default-desktop' */),
    mobile: () => import('~/components/layouts/default/mobile' /* webpackChunkName: 'layouts-default-mobile' */),
    desktopWhiteLabel: () => import('~/components/layouts/white-label/desktop' /* webpackChunkName: 'layouts-white-label-desktop' */),
    mobileWhiteLabel: () => import('~/components/layouts/white-label/mobile' /* webpackChunkName: 'layouts-white-label-mobile' */)
  }
})
export default class LayoutDefaultWrapper extends Vue {
  @State klook!: Data.Klook

  get componentName() {
    const { klook } = this
    if (klook.utilConfig.whiteLabelUtilConfig) {
      return `${klook.platform}WhiteLabel`
    }
    return klook.platform
  }
}
